import React, { useContext } from 'react';
import FormContainer from './FormContainer';
import BillingSheet from '../sheets/BillingSheet';
import billingService from '../../api/billingService';
import billingIcon from '../../images/billing-icon.svg';
import { UserContext } from '../../contexts/UserContext';

function BillingForm() {
  const { user }: any = useContext(UserContext);
  return (
    <React.Fragment>
      <FormContainer
        title="Billing"
        icon={billingIcon}
        withExportDate
        getDataFunction={async (params) => {
          return billingService.postBilling(
            params.invoices,
            params.selectedBranch?.branchId,
            params.exportDate,
            user.name,
          );
        }}
        getSheet={(data) => {
          if (data) {
            return (
              <div>
                <BillingSheet data={data} />;
              </div>
            );
          }
        }}
        getDocumentTitle={(data) => {
          const { billingDetail } = data;
          const no = billingDetail.map((bd: any) => bd.invoiceNo);
          return `Billing ${data.header.documentNo} ${
            no ? `(${no.join(',')})` : ''
          }`;
        }}
      />
    </React.Fragment>
  );
}

export default BillingForm;
