import { useState } from 'react';

const useGetRemoteServiceWrapper = (initLoadingState = false) => {
  const [state, setState] = useState({
    loading: initLoadingState,
    timeSpent: -1,
  });
  const callRemoteServiceWrapper = async (func: () => void) => {
    let startTime = null;
    try {
      setState({
        loading: true,
        timeSpent: -1,
      });
      startTime = Date.now();
      await func();
      const endTime = Date.now();
      setState({
        loading: false,
        timeSpent: endTime - startTime,
      });
      return Promise.resolve();
    } catch (error) {
      const endTime = Date.now();
      console.error({
        ERROR: error,
      });
      setState({
        loading: false,
        timeSpent: startTime != null ? endTime - startTime : -1,
      });
      return Promise.reject();
    }
  };

  return {
    loading: state.loading,
    timeSpent: state.timeSpent,
    callRemoteServiceWrapper,
  };
};

export default useGetRemoteServiceWrapper;
