import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import {
  SSheet,
  SSheetHeader,
  SSheetTitle,
  SSheetHeaderRowTitle,
} from './SheetUI';
import Table from '../tables/Table';
import { AlignType, DataType } from '../tables/TableUtils';

const SSheetHeaderContent = styled.div`
  display: grid;
  grid-template-columns: 40% 30% 30%;
  font-size: 0.8rem;
`;

const SContact = styled.div`
  display: flex;
  flex-direction: column;
`;
const SDateAndRefNo = styled.div`
  display: flex;
  flex-direction: column;
`;

const SRowValue = styled.div``;

const SOrganization = styled.div`
  display: flex;
  flex-direction: column;
`;

const SSummary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SRSummaryRow = styled.div`
  width: 40%;
  margin-left: auto;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

const SSummaryRowValue = styled.div`
  width: 50%;
  text-align: right;
`;

const SSignature = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 120px;
`;

const SSignatureRow = styled.div`
  display: grid;
  grid-template-columns: 30% 30%;
  grid-column-gap: 40%;
`;

const SLine = styled.div`
  width: 220px;
  border-top: 1px solid black;
`;

const SNote = styled.p`
  font-style: italic;
  margin-top: 50px;
`;

export default function ReceiptTaxInvoiceSheet({
  data,
}: {
  data: any;
}) {
  const tableConfig = {
    columnHeaders: [
      {
        title: 'Description',
        columnValue: 'description',
        width: '15%',
      },
      {
        title: 'Quantity',
        columnValue: 'quantity',
        width: '15%',
        align: 'right' as AlignType,
        type: 'number' as DataType,
      },
      {
        title: 'Unit Price',
        columnValue: 'unitPrice',
        width: '15%',
        align: 'right' as AlignType,
        type: 'number' as DataType,
      },
      {
        title: 'Discount',
        columnValue: 'discountRate',
        width: '15%',
        align: 'right' as AlignType,
        type: 'number' as DataType,
      },
      {
        title: 'Tax',
        columnValue: 'taxRate',
        width: '15%',
        align: 'right' as AlignType,
        type: 'percentage' as DataType,
      },
      {
        title: 'Amount',
        columnValue: 'lineAmount',
        width: '15%',
        align: 'right' as AlignType,
        type: 'number' as DataType,
      },
    ],
    data: data?.invoices || [],
  };

  return (
    <SSheet>
      <SSheetHeader>
        <SSheetTitle>RECEIPT/TAX INVOICE (ORIGINAL)</SSheetTitle>
        <SSheetHeaderContent>
          <SContact>
            <div>{data?.customer?.name}</div>
            <div>{data?.customer?.address?.AddressLine1}</div>
            <div>{data?.customer?.address?.AddressLine2}</div>
            <div>{data?.customer?.address?.AddressLine3}</div>
            {data?.customer?.taxId && (
              <div>Tax Id: {data?.customer?.taxId}</div>
            )}
          </SContact>
          <SDateAndRefNo>
            <SSheetHeaderRowTitle>Date</SSheetHeaderRowTitle>
            <SRowValue>
              {`${moment(data?.date).format('DD-MMM-YYYY')}`}
            </SRowValue>
            <br />
            <SSheetHeaderRowTitle>No.</SSheetHeaderRowTitle>
            <SRowValue>{data.docNo}</SRowValue>
            <br />
            <SSheetHeaderRowTitle>
              Ref Invoice No.
            </SSheetHeaderRowTitle>
            <SRowValue>{data.refInvoiceNo}</SRowValue>
          </SDateAndRefNo>
          <SOrganization>
            <SRowValue>{data?.organization.name}</SRowValue>
            <br />
            <SRowValue>
              {data?.organization?.address?.AddressLine1}
            </SRowValue>
            <SRowValue>
              {data?.organization?.address?.AddressLine2}
            </SRowValue>
            <SRowValue>
              {data?.organization?.address?.AddressLine3}
            </SRowValue>
            <SRowValue>
              {data?.organization?.address?.AddressLine4}
            </SRowValue>
            <br />
            <SRowValue>
              Tel: {data?.organization?.phoneNumbers}
            </SRowValue>
            {data?.organization?.taxId && (
              <SRowValue>
                Tax ID: {data?.organization?.taxId}
              </SRowValue>
            )}
          </SOrganization>
        </SSheetHeaderContent>
      </SSheetHeader>
      <Table {...tableConfig} />
      <br />
      <SSummary>
        {Object.keys(data?.taxSummary).map((key, index) => {
          return (
            <Fragment key={`tax-summary-${index}`}>
              <SRSummaryRow>
                <SSummaryRowValue>
                  Tax Base{' '}
                  {Number(key).toLocaleString(undefined, {
                    minimumFractionDigits: 1,
                  })}
                  %
                </SSummaryRowValue>
                <SSummaryRowValue>
                  {Number(
                    data?.taxSummary[key].lineAmount,
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </SSummaryRowValue>
              </SRSummaryRow>

              <SRSummaryRow>
                <SSummaryRowValue>
                  Total Sales Tax{' '}
                  {Number(key).toLocaleString(undefined, {
                    minimumFractionDigits: 1,
                  })}
                  %
                </SSummaryRowValue>
                <SSummaryRowValue>
                  {Number(
                    data?.taxSummary[key].taxAmount,
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 1,
                  })}
                </SSummaryRowValue>
              </SRSummaryRow>
            </Fragment>
          );
        })}
        <br />
        <SRSummaryRow>
          <SSummaryRowValue>TOTAL</SSummaryRowValue>
          <SSummaryRowValue>
            {Number(data?.total).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </SSummaryRowValue>
        </SRSummaryRow>
      </SSummary>

      <SSignature>
        <SSignatureRow>
          <div></div>
          <div>{`${moment(data?.date).format('DD/MMM/YYYY')}`}</div>
        </SSignatureRow>
        <SSignatureRow>
          <SLine>Received by</SLine>
          <SLine>Date</SLine>
        </SSignatureRow>
      </SSignature>

      <SNote>
        "This Document will complete after GoPomelo Co., Ltd received
        payment"
      </SNote>
    </SSheet>
  );
}
