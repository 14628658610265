import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import {
  ISelectedBranch,
  ISubmitModel,
  IDocumentType,
} from '../../model/InputModel';
import BranchDropdown from './BranchDropdown';
import DateInput, { getDefaultDate } from './DateInput';
import InvoiceInput from './InvoiceInput';
import ErrorBox from '../forms/ErrorBox';
import DocumentTypeDropdown from './DocumentTypeDropdown';
import ConvertRateInput from './ConvertRateInput';
import RemarksInput from './RemarkInput';

const SBody = styled.div`
  height: 650px;
  width: 100%;
  margin-top: 3%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

const SForm = styled.form`
  width: 30%;
  min-width: 500px;

  display: flex;
  flex-direction: column;
  z-index: -1;
`;

const SExport = styled.button`
  margin-top: 30px;
  background: #a83372 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  height: 48px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    background: black;
    opacity: 0.5;
    cursor: default;
  }
  outline: none;
`;
const STitle = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  color: #a83372;
  font-size: 2rem;
  margin: 0px;
  border-radius: 12px 0px 0px 12px;
`;
const SContainer = styled.div`
  height: 100%;
  border-radius: 0px 12px 12px 0px;
  padding: 0px 156px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255, 0.5);
  z-index: 2;
`;

const SImg = styled.img`
  width: 80px;
  margin-bottom: 30px;
`;

const SText = styled.div`
  width: 200px;
  text-align: center;
`;

interface IInputForm {
  error: string;
  title: string;
  onSubmit: (params: ISubmitModel) => void;
  icon: any;
  withExportDate?: boolean;
  inputDateType?: string;
  noInvoiceInput?: boolean;
  withDocumentType?: boolean;
  checkBeforeExport?: boolean;
  withConvertRate?: boolean;
  withRemarks?: boolean;
  googleSheetLink: any;
  exportData?: any;
}

function InputForm({
  error,
  icon,
  title,
  onSubmit,
  withExportDate,
  inputDateType,
  noInvoiceInput,
  withDocumentType,
  checkBeforeExport,
  withConvertRate,
  withRemarks,
  googleSheetLink,
  exportData,
}: IInputForm) {
  const [selectedBranch, setSelectdBranch] = useState<
    ISelectedBranch | null | undefined
  >();
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<IDocumentType>();
  const [isGenerate, setIsGenerate] = useState(false);

  const [invoices, setInvoices] = useState<string[]>();
  const [convertRate, setConvertRate] = useState<number>(1);
  const [remarks, setRemarks] = useState<string>('');
  const [exportDate, setExportDate] = useState<string>(
    getDefaultDate(inputDateType),
  );
  const formRef = useRef<any>();
  const isMissingMandatoryField = useMemo(() => {
    return (
      !selectedBranch ||
      !selectedBranch.branchId ||
      (!noInvoiceInput && (!invoices || invoices.length === 0))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBranch?.branchId, noInvoiceInput, invoices?.length]);

  const optionIsMissingMandatoryField = useMemo(() => {
    return withDocumentType
      ? selectedDocumentType
        ? false
        : true
      : true;
  }, [withDocumentType, selectedDocumentType]);
  const handleOnBranchChange = (branch: ISelectedBranch) => {
    setSelectdBranch(branch);
  };
  const handleOnDocumentTypeChange = (
    documentType: IDocumentType,
  ) => {
    setSelectedDocumentType(documentType);
  };

  const handleOnInvoiceChange = (invoices: string[]) => {
    setInvoices(invoices);
  };
  const handleConvertRateChange = (rate: number) => {
    setConvertRate(rate);
  };
  const handleRemarksChange = (text: string) => {
    setRemarks(text);
  };
  return (
    <React.Fragment>
      <SBody>
        <STitle>
          <SImg src={icon} />
          <SText>{title}</SText>
        </STitle>
        <SContainer>
          <ErrorBox error={error} />
          <SForm
            ref={(e) => {
              formRef.current = e;
            }}
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit({
                selectedBranch,
                invoices,
                exportDate,
                selectedDocumentType,
                convertRate,
                remarks,
              });
            }}
          >
            <BranchDropdown
              selectedBranch={selectedBranch}
              onBranchChange={handleOnBranchChange}
              withDocumentType={withDocumentType}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '15px',
              }}
            >
              {withDocumentType && (
                <DocumentTypeDropdown
                  selectedDocumentType={selectedDocumentType}
                  handleOnTaxTypeChange={handleOnDocumentTypeChange}
                />
              )}
              {withConvertRate && (
                <ConvertRateInput
                  onConvertRateChange={handleConvertRateChange}
                />
              )}
            </div>

            {!noInvoiceInput && (
              <InvoiceInput onInvoiceChange={handleOnInvoiceChange} />
            )}
            {withExportDate && (
              <DateInput
                inputDateType={inputDateType}
                onDateChange={(date) => {
                  setExportDate(date);
                }}
              />
            )}
            {withRemarks && (
              <RemarksInput onRemarksChange={handleRemarksChange} />
            )}
            {googleSheetLink?.googleSheetLink && (
              <SGoogleSheetLink
                onClick={() => {
                  window.open(googleSheetLink?.googleSheetLink);
                }}
              >
                OPEN GOOGLE SHEETS LINK
              </SGoogleSheetLink>
            )}
            {checkBeforeExport ? (
              <SCheckContainer>
                <SGenerate
                  disabled={
                    isMissingMandatoryField ||
                    optionIsMissingMandatoryField
                  }
                  onClick={() => {
                    setIsGenerate(true);
                  }}
                >
                  Generate
                </SGenerate>
                <SSend
                  className={
                    !isGenerate || !googleSheetLink?.isNewFile
                      ? 'disabled'
                      : ''
                  }
                  onClick={() => {
                    if (!isGenerate || !googleSheetLink?.isNewFile) {
                    } else {
                      exportData({
                        sheetId: googleSheetLink?.sheetId,
                        branchCompanyName:
                          googleSheetLink?.branchCompanyName,
                      });
                      setIsGenerate(false);
                    }
                  }}
                >
                  Send
                </SSend>
              </SCheckContainer>
            ) : (
              <SExport disabled={isMissingMandatoryField}>
                Export
              </SExport>
            )}
          </SForm>
        </SContainer>
      </SBody>
    </React.Fragment>
  );
}

export default InputForm;
const SGoogleSheetLink = styled.div`
  margin-top: 15px;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
`;
const SCheckContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SGenerate = styled.button`
  margin-top: 15px;
  background: #a83372 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  height: 48px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  width: 200px;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    background: black;
    opacity: 0.5;
    cursor: default;
  }
  outline: none;
`;
const SSend = styled.div`
  margin-top: 15px;
  background: #a83372 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  height: 48px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease-in-out;
  width: 200px;
  &:hover {
    opacity: 0.9;
  }
  &.disabled {
    background: black;
    opacity: 0.5;
    cursor: default;
  }
  outline: none;
`;
