import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import ErrorBox from '../ErrorBox';
import ReceiptTaxInvoiceService from '../../../api/ReceiptTaxInvoiceService';
import useGetRemoteServiceWrapper from '../../../custom-hooks/useGetRemoteServiceWrapper';
import { ReactComponent as InfoIcon } from '../../../images/error-icon.svg';

const SContainer = styled.div`
  width: 230px;
  height: 230px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const SButton = styled.button`
  color: white;
  border-radius: 8px;
  width: 140px;
  height: 40px;
  cursor: pointer;
  border: none;
  outline: none;
  background: ${(props: any) => props.background};
  transition: all 0.2s ease-in-out;
  margin: auto;
  &:hover {
    opacity: 0.8;
  }
`;

const SCancel = styled(SButton)`
  background: #a83372;
`;

const SMessage = styled.div`
  margin-bottom: 10px;
`;
const SConfirmActions = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const SInput = styled.input`
  border: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  outline: none;
  margin-bottom: 20px;
  &.alert {
    box-shadow: 0px 0px 6px red;
  }
`;

const SConfirmYes = styled(SButton)`
  background: #3894ff;
`;
const SConfirmNo = styled(SButton)`
  margin-top: 10px;
  background: #757677;
`;

const SErrorBoxFullWidth = styled(ErrorBox)`
  width: 90%;
  text-align: center;
`;

const SInfoBox = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  color: #6fce84;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  margin-top: 20px;
  padding-bottom: 10px;
  width: 90%;
  svg {
    width: 50px;
    height: 80px;
  }
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const INITIAL = 0;
const CONFIRM = 1;
const CANCEL_SUCCESS = 2;

export default function RemoveReceiptTaxInvoice({
  data,
}: {
  data: any;
}) {
  const cancelComment = useRef<HTMLInputElement>(null);
  const [state, setState] = useState(INITIAL);
  const [error, setError] = useState<any>();
  const [isAlert, setIsAlert] = useState(false);

  const { callRemoteServiceWrapper } = useGetRemoteServiceWrapper();

  return (
    <SContainer>
      {state === INITIAL && (
        <>
          <SMessage>Cancel this Receipt/Tax Invoice</SMessage>
          <SCancel
            onClick={() => {
              setState(CONFIRM);
            }}
          >
            Cancel
          </SCancel>
        </>
      )}

      {!error && state === CONFIRM && (
        <>
          <SMessage>
            Are you sure you want to cancel this Receipt/Tax Invoice?
          </SMessage>
          <SConfirmActions>
            {isAlert && (
              <div style={{ fontSize: '8px', color: 'red' }}>
                Please add a comment
              </div>
            )}
            <SInput
              className={isAlert ? 'alert' : ''}
              ref={cancelComment}
            />
            <SConfirmYes
              onClick={async () => {
                cancelComment?.current?.value
                  ? callRemoteServiceWrapper(async () => {
                      try {
                        await ReceiptTaxInvoiceService.cancelReceiptTaxInvoice(
                          data.recordKey,
                          cancelComment?.current?.value || '',
                        );
                        setIsAlert(false);
                        setState(CANCEL_SUCCESS);
                      } catch (e) {
                        console.log(e);
                        setError(
                          'Unable to cancel this Receipt Tax/Invoice',
                        );
                      }
                    })
                  : setIsAlert(true);
              }}
            >
              Yes
            </SConfirmYes>
            <SConfirmNo
              onClick={() => {
                setState(INITIAL);
              }}
            >
              No
            </SConfirmNo>
          </SConfirmActions>
        </>
      )}
      {state === CANCEL_SUCCESS && (
        <>
          <SInfoBox>
            <InfoIcon />
            Receipt/Tax Invoice is cancelled.
          </SInfoBox>
        </>
      )}

      {error && <SErrorBoxFullWidth error={error} width="90%" />}
    </SContainer>
  );
}
