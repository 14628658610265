import React from 'react';
import styled from 'styled-components';
import { ISubmitModel } from '../../../model/InputModel';
interface IDuplicateSheet {
  webLink: string;
  setPopupDuplicate: Function;
  setData: Function;
  generateFunction: Function;
  params: ISubmitModel;
  reason: string;
  setReason: Function;
}
const DuplicateSheet = ({
  webLink,
  setPopupDuplicate,
  setData,
  generateFunction,
  params,
  reason,
  setReason,
}: IDuplicateSheet) => {
  return (
    <SPopup>
      <SDetail>
        <div>
          WARNING : This invoice
          {`(${params.invoices?.map((e) => `${e} `)})`} has already
          been generated in
          {`${params.selectedDocumentType?.documentTypeName} (${params.selectedDocumentType?.documentTypeId}) `}
          Do you still want to regenerate?
        </div>
        <div>
          <div>If yes, pls give the reason : </div>
          <input onChange={(e) => setReason(e.target.value)} />
        </div>
        <SButtonContainer>
          <div
            onClick={() => {
              window.open(webLink);
              setData(undefined);
              setReason('');
              setPopupDuplicate(false);
            }}
          >
            Open the previous version
          </div>
          <div
            className={!reason ? 'disable' : ''}
            onClick={() => {
              if (reason) {
                generateFunction();
                setReason('');
                setPopupDuplicate(false);
              }
            }}
          >
            Generate
          </div>
        </SButtonContainer>
      </SDetail>
    </SPopup>
  );
};
export default DuplicateSheet;
const SButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  width: 100%;
  & > div {
    cursor: pointer;
    border: 1px solid black;
    padding: 8px;
    border-radius: 1rem;
  }
  & > div:nth-child(1) {
    background-color: white;
  }
  & > div:nth-child(2) {
    background-color: #a83372;
    color: white;
    &.disable {
      background-color: grey;
    }
  }
`;
const SPopup = styled.div`
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9037990196078431) 0%,
    rgba(65, 58, 58, 0.8365721288515406) 7%,
    rgba(106, 106, 106, 0.665703781512605) 100%
  );
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
`;

const SDetail = styled.div`
  overflow-y: auto;
  width: 50%;
  height: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0px 20px;
  & > div:nth-child(1) {
    font-weight: bolder;
    font-size: 20px;
    color: red;
    text-align: center;
  }
  & > div:nth-child(2) {
    width: 100%;
    display: flex;
    & > input {
      width: 60%;
      margin-left: 10px;
    }
  }
`;
