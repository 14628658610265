import React from 'react';
import styled from 'styled-components/macro';
import {
  STable,
  STableContainer,
  STBody,
  STd,
  STh,
  STHead,
  STr,
} from './TableUi';

export const SSignatureTableContainer = styled(STableContainer)`
  margin: 55px 0 10px 0;
  position: relative;
  page-break-inside: avoid !important;
  display: inline-block;
`;

export const SHighTd = styled(STd)`
  height: 80px;
`;

interface ISignatureTable {
  preparedBy?: string;
}

function SignatureTable({ preparedBy } : ISignatureTable) {
  return (
    <SSignatureTableContainer>
      <STable>
        <STHead>
          <STr>
            <STh>Prepared by</STh>
            <STh>Checked by</STh>
            <STh>Prepared by</STh>
            <STh>Recevier/Payer</STh>
          </STr>
        </STHead>
        <STBody>
          <STr>
            <SHighTd>{preparedBy}</SHighTd>
            <SHighTd />
            <SHighTd />
            <SHighTd />
          </STr>
        </STBody>
      </STable>
    </SSignatureTableContainer>
  );
}

export default SignatureTable;
