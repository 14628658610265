import React from 'react';
import Table from '../tables/Table';
import moment from 'moment';
import SignatureTable from '../tables/SignatureTable';
import { AlignType, DataType } from '../tables/TableUtils';
import {
  SSheet,
  SSheetHeader,
  SSheetHeaderRow,
  SSheetHeaderRowEntry,
  SSheetHeaderRowTitle,
  SSheetHeaderRowValue,
  SSheetTopic,
} from './SheetUI';
import { STableContainer } from '../tables/TableUi';

function ReceiveVoucherSheet({ data }: { data: any }) {
  const {
    name,
    type,
    supplierName,
    description,
    date,
    documentNo,
  } = data;

  const sheetHeader = {
    name,
    type,
    supplierName,
    description,
    date,
    documentNo,
  };

  const tablesConfig = [
    {
      columnHeaders: [
        {
          title: 'Account Code',
          columnValue: 'accountCode',
          width: '15%',
        },
        {
          title: 'Account Name',
          columnValue: 'accountName',
          width: '40%',
        },
        {
          title: 'Debit',
          columnValue: 'debit',
          width: '15%',
          align: 'right' as AlignType,
          type: 'number' as DataType,
          editable: true,
        },
        {
          title: 'Credit',
          columnValue: 'credit',
          width: '15%',
          align: 'right' as AlignType,
          type: 'number' as DataType,
          editable: true,
        },
        {
          title: 'Notes',
          columnValue: 'jobs',
          width: '15%',
          align: 'right' as AlignType,
          type: 'number' as DataType,
          editable: true,
        },
      ],
      data: data?.accountList || [],
    },

    {
      title: 'Payment Detail',
      columnHeaders: [
        {
          title: 'Date',
          columnValue: 'date',
          width: '15%',
          type: 'date' as DataType,
        },
        {
          title: 'Invoice No.',
          columnValue: 'invoiceNo',
          width: '15%',
        },
        {
          title: 'Description',
          columnValue: 'description',
          width: '25%',
        },
        {
          title: 'Sub total',
          columnValue: 'subtotal',
          width: '15%',
          align: 'right' as AlignType,
          type: 'number' as DataType,
          editable: true,
        },
        {
          title: 'Vat 7%',
          columnValue: 'vat',
          width: '15%',
          align: 'right' as AlignType,
          type: 'number' as DataType,
          editable: true,
        },
        {
          title: 'Total Amount',
          columnValue: 'totalAmount',
          width: '15%',
          align: 'right' as AlignType,
          type: 'number' as DataType,
          editable: true,
        },
      ],
      data: data?.paymentList || [],
      // tableFooter: [
      //   {
      //     title: 'Withholding Tax',
      //     value: '48.12',
      //     type: 'number' as DataType,
      //   },
      //   {
      //     title: 'Net Payment Amount',
      //     value: '1604.04',
      //     type: 'number' as DataType,
      //   },
      // ],
    },
  ];

  return (
    <SSheet>
      <SSheetHeader>
        <SSheetTopic>{sheetHeader?.name}</SSheetTopic>
        <SSheetTopic>Receive Voucher</SSheetTopic>
        <SSheetHeaderRow>
          <SSheetHeaderRowEntry>
            <SSheetHeaderRowTitle>Customer Name</SSheetHeaderRowTitle>{' '}
            <SSheetHeaderRowValue>
              {sheetHeader?.supplierName}
            </SSheetHeaderRowValue>
          </SSheetHeaderRowEntry>
          <SSheetHeaderRowEntry>
            <SSheetHeaderRowTitle>Date</SSheetHeaderRowTitle>
            <SSheetHeaderRowValue>
              {`${moment(sheetHeader?.date).format('DD-MMM-YYYY')}`}
            </SSheetHeaderRowValue>
          </SSheetHeaderRowEntry>
        </SSheetHeaderRow>

        <SSheetHeaderRow>
          <SSheetHeaderRowEntry>
            <SSheetHeaderRowTitle>Description</SSheetHeaderRowTitle>{' '}
            <SSheetHeaderRowValue>
              {sheetHeader?.description}
            </SSheetHeaderRowValue>
          </SSheetHeaderRowEntry>
          <SSheetHeaderRowEntry>
            <SSheetHeaderRowTitle>Document No.</SSheetHeaderRowTitle>
            <SSheetHeaderRowValue>
              {sheetHeader?.documentNo}
            </SSheetHeaderRowValue>
          </SSheetHeaderRowEntry>
        </SSheetHeaderRow>
      </SSheetHeader>
      <STableContainer>
        {tablesConfig &&
          tablesConfig.map((config, index) => {
            if (config.data && config.data.length > 0) {
              return (
                <Table
                  key={`table-${index}`}
                  {...config}
                  displayTotal
                />
              );
            } else {
              return null;
            }
          })}

        <SignatureTable preparedBy={data.preparedBy} />
      </STableContainer>
    </SSheet>
  );
}

export default ReceiveVoucherSheet;
