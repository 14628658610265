import React from 'react';
import styled from 'styled-components/macro';

const SInput = styled.input`
  border: none;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
  padding: 0px 20px;
  font-size: 16px;
  &::placeholder {
    color: #cccccc;
  }
  &:focus {
    outline: none;
  }
`;

const SConvertRateText = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
`;
const SContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function ConvertRateInput({
  onConvertRateChange,
}: {
  onConvertRateChange: (rate: number) => void;
}) {
  return (
    <SContainer>
      <SConvertRateText>Convert Rate</SConvertRateText>
      <SInput
        defaultValue={1}
        onKeyPress={(event) => {
          if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onChange={(event) =>
          onConvertRateChange(parseFloat(event.target.value))
        }
      />
    </SContainer>
  );
}

export default ConvertRateInput;
