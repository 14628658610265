import React, { useState } from 'react';
import styled from 'styled-components';
import { IDocumentType } from '../../model/InputModel';
import dropdownIcon from '../../images/dropdown-icon.svg';

const tax_type_choice = [
  { taxtypeName: 'Tax invoice', taxtypeId: '388' },
  { taxtypeName: 'Tax invoice/ Receipt', taxtypeId: 'T03' },
  { taxtypeName: 'Receipt', taxtypeId: 'T01' },
  { taxtypeName: 'Credit note', taxtypeId: '81' },
  { taxtypeName: 'Debit note', taxtypeId: '80' },
];
interface IDocumentTypeDropdown {
  selectedDocumentType: IDocumentType | undefined;
  handleOnTaxTypeChange: (taxType: IDocumentType) => void;
}
const DocumentTypeDropdown = ({
  selectedDocumentType,
  handleOnTaxTypeChange,
}: IDocumentTypeDropdown) => {
  const [dropdown, setDropdown] = useState(false);
  const handleBranch = (
    documentTypeName: string,
    documentTypeId: string,
  ) => {
    setDropdown(false);
    handleOnTaxTypeChange({ documentTypeName, documentTypeId });
  };
  const handleDropdown = () => {
    setDropdown(!dropdown);
  };
  return (
    <SContainer>
      <STaxTypeText>Document Type</STaxTypeText>
      <SDropdownContainer>
        {dropdown && (
          <SDropdown>
            {tax_type_choice.map((taxtype: any, i: number) => (
              <STaxTypeChoice
                key={i}
                onClick={() =>
                  handleBranch(taxtype.taxtypeName, taxtype.taxtypeId)
                }
              >
                {taxtype.taxtypeName}
              </STaxTypeChoice>
            ))}
          </SDropdown>
        )}
        <STaxTypeDropdown
          className={!selectedDocumentType ? 'placeholder' : ''}
          onClick={() => handleDropdown()}
        >
          {selectedDocumentType
            ? selectedDocumentType.documentTypeName
            : 'Select Document type'}
          <SDropdownIcon
            src={dropdownIcon}
            className={dropdown ? 'open' : ''}
          />
        </STaxTypeDropdown>
      </SDropdownContainer>
    </SContainer>
  );
};

export default DocumentTypeDropdown;
const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
const STaxTypeDropdown = styled.div`
  height: 48px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
  &.placeholder {
    color: #cccccc;
  }
`;
const STaxTypeText = styled.div`
  margin-bottom: 10px;
  margin-left: 10px;
`;
const SDropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;
const SDropdown = styled.div`
  padding: 38px 0px 15px 0px;
  width: 95%;
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 18px #00000029;
  border-radius: 8px 8px 14px 14px;
  top: 100%;
  left: 20px;
  z-index: 1;
  overflow-y: auto;
`;
const STaxTypeChoice = styled.div`
  cursor: pointer;
  letter-spacing: 0px;
  color: #626262;
  font-size: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  &:hover {
    background: #a2316e 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
`;
const SDropdownIcon = styled.img`
  cursor: pointer;
  &.open {
    transform: rotateX(180deg);
  }
  margin-left: auto;
`;
