import React from 'react';
import styled from 'styled-components/macro';

const SInvoiceDate = styled.div`
  margin-top: 30px;
  margin-left: 10px;
`;

const SInput = styled.input`
  border: none;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
  margin-top: 10px;
  padding: 0px 20px;
  font-size: 16px;
  &::placeholder {
    color: #cccccc;
  }
  &:focus {
    outline: none;
  }
`;

export const getDefaultDate = (
  inputDateType: string | undefined = 'date',
): string => {
  const dateString = inputDateType === 'month' ? 7 : 10;
  return new Date().toISOString().substring(0, dateString);
};

export default function DateInput({
  onDateChange,
  inputDateType,
}: {
  onDateChange: (date: string) => void;
  inputDateType?: string;
}) {
  return (
    <>
      <SInvoiceDate>
        Export {inputDateType === 'month' ? 'Month' : 'Date'}
      </SInvoiceDate>
      <SInput
        type={inputDateType || 'date'}
        placeholder="Enter Invoice No."
        onChange={(event) => onDateChange(event.target.value)}
        defaultValue={getDefaultDate(inputDateType)}
      />
    </>
  );
}
