export const ROUTES_URL = {
  default: '/',
  main: '/main',
};

export const ROUTE_MAIN_URL = {
  billing: `${ROUTES_URL.main}/billing`,
  receiptTaxInvoice: `${ROUTES_URL.main}/receipt-tax-invoice`,
  uploadReceiptTaxInvoice: `${ROUTES_URL.main}/upload-receipt-tax-invoice`,
  receiptTaxInvoiceMonthlyReport: `${ROUTES_URL.main}/receipt-tax-invoice-monthly-report`,
  receiveVoucher: `${ROUTES_URL.main}/receive-voucher`,
  paymentVoucher: `${ROUTES_URL.main}/payment-voucher`,
};
