import React from 'react';
import styled from 'styled-components/macro';

const SInput = styled.input`
  border: none;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
  margin-top: 10px;
  padding: 0px 20px;
  font-size: 16px;
  &::placeholder {
    color: #cccccc;
  }
  &:focus {
    outline: none;
  }
`;

const SInvoiceText = styled.div`
  margin-top: 30px;
  margin-left: 10px;
`;

function InvoiceInput({
  onInvoiceChange,
}: {
  onInvoiceChange: (invoices: string[]) => void;
}) {
  const handleSpiltInvoice = (invoice: any) => {
    const invoiceArr = invoice.split(/[ |,]+/);
    const noEmpty = invoiceArr
      .map((i: string) => i.trim())
      .filter((i: string) => i.length > 0);
    onInvoiceChange(noEmpty);
  };

  return (
    <>
      <SInvoiceText>Invoice No.</SInvoiceText>
      <SInput
        placeholder="Enter Invoice No."
        onChange={(event) => handleSpiltInvoice(event.target.value)}
      />
    </>
  );
}

export default InvoiceInput;
