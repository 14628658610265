import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as ErrorIcon } from '../../images/error-icon.svg';

const SErrorBox = styled.div`
  color: #a83372;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 30px;
  margin-bottom: 5px;
  z-index: 99999;
  & > svg {
    width: 20px;
    height: 50px;
    margin-right: 10px;
  }
`;

function ErrorBox({
  error,
  width,
}: {
  error: string;
  width?: string;
}) {
  if (!error) {
    return null;
  }
  return (
    <SErrorBox style={{ width }}>
      <ErrorIcon />
      {error}
    </SErrorBox>
  );
}

export default ErrorBox;
