import accountingPortalAxios from './service';

const api = {
  postBilling: async (
    invoiceNumbers: any,
    tenantId: any,
    exportDate: any,
    name: string,
  ) => {
    const result = await accountingPortalAxios.post('/billing', {
      tenantId,
      invoiceNumbers,
      exportDate,
      by: name,
    });
    return result.data;
  },

  getTaxInvoiceByMonth: async (
    tenantId: string,
    monthYYYYmm: string,
  ) => {
    if (!tenantId || !monthYYYYmm) {
      return;
    }
    const params = new URLSearchParams({
      month: monthYYYYmm,
      tenantId,
    });

    const url = `/receipt-tax-invoice/get-tax-invoice-by-month?${params.toString()}`;
    const result = await accountingPortalAxios.get(url);

    return result.data;
  },
};

export default api;
