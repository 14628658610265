import accountingPortalAxios from './service';

const api = {
  postReceiptTaxInvoice: async (
    invoiceNumbers: any,
    tenantId: any,
    exportDate: any,
  ) => {
    const result = await accountingPortalAxios.post(
      '/receipt-tax-invoice',
      {
        tenantId,
        invoiceNumbers,
        exportDate,
      },
    );
    return result.data;
  },

  cancelReceiptTaxInvoice: async (
    recordKey: string,
    comment: string,
  ) => {
    const result = await accountingPortalAxios.delete(
      '/receipt-tax-invoice',
      {
        data: {
          recordKey,
          comment,
        },
      },
    );
    return result.data;
  },

  getTaxInvoiceByMonth: async (
    tenantId: string,
    monthYYYYmm: string,
  ) => {
    if (!tenantId || !monthYYYYmm) {
      return;
    }
    const params = new URLSearchParams({
      month: monthYYYYmm,
      tenantId,
    });

    const url = `/receipt-tax-invoice/get-tax-invoice-by-month?${params.toString()}`;
    const result = await accountingPortalAxios.get(url);

    return result.data;
  },
};

export default api;
