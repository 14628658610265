import React, { useState, useMemo, createContext } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import lightTheme from './light';
import darkTheme from './dark';
import commonTheme from './common';

interface ThemeProps {
  mode?: string;
}

const DEFAULT_MODE = 'light';
export const DEFAULT_BREAKPOINT = 620;

export const ThemeContext = createContext({
  mode: DEFAULT_MODE,
  handleSetThemeMode: (newMode: string) => {},
});

const ThemeContextProvider: React.SFC<ThemeProps> = ({
  children,
  mode: modeProps = DEFAULT_MODE,
}) => {
  const [mode, setMode] = useState(modeProps);
  const theme = useMemo(() => {
    let theme1;
    if (mode === 'light') theme1 = lightTheme;
    else if (mode === 'dark') theme1 = darkTheme;
    return { ...theme1, commonTheme };
  }, [mode]);
  const handleSetThemeMode = (newMode: string): void => {
    setMode(newMode);
  };
  return (
    <ThemeContext.Provider value={{ mode, handleSetThemeMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
