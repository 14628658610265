import moment from 'moment';

export type DataType = 'number' | 'date' | 'percentage' | undefined;

export type AlignType =
  | 'inherit'
  | 'end'
  | 'initial'
  | 'start'
  | 'left'
  | 'center'
  | 'right'
  | 'justify'
  | '-moz-initial'
  | 'revert'
  | 'unset'
  | 'match-parent'
  | undefined;

export const renderValueByType = (type: DataType, value: string) => {
  if (type === 'number') {
    if (value === undefined || value === null || value === '') {
      return '';
    }
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  } else if (type === 'date') {
    return moment(value).format('DD-MMM-YYYY');
  } else if (type === 'percentage') {
    return `${Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 1,
    })}%`;
  } else {
    return value;
  }
};
