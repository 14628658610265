import React, { useRef, ReactNode } from 'react';
import styled from 'styled-components/macro';
import closeIcon from '../images/close.svg';
import { ReactComponent as GoogleSheetLogo } from '../images/google-sheets.svg';

const SPopup = styled.div`
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9037990196078431) 0%,
    rgba(65, 58, 58, 0.8365721288515406) 7%,
    rgba(106, 106, 106, 0.665703781512605) 100%
  );
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
`;
const STopBar = styled.div`
  display: flex;
  font-size: 20px;
  letter-spacing: 0px;
  min-height: 64px;
  color: #ffffff;
  font-size: 20px;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  box-sizing: border-box;
  /* position: fixed; */
  width: 100%;
`;

const SRightComponent = styled.div`
  position: fixed;
  right: 50px;
  top: 20%;
`;

const SSheetName = styled.div``;
const SGroup = styled.div`
  display: flex;
  width: 800px;
  justify-content: space-between;
  font-size: 17px;
  & > div > svg {
    width: 18px;
    margin-left: 7px;
  }
`;

const SClose = styled.img`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;
const SDetail = styled.div`
  overflow-y: auto;
  width: 100%;
  /* height: 100%; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  @media print {
    @page {
      margin-top: 15px;
      margin-bottom: 5px;
    }
    position: relative;
    display: block;
  }
`;
const SButton = styled.div`
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: black;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #3a3a3a;
  }
`;
interface IPopup {
  show: boolean;
  onClose: () => void;
  title: string;
  sheet: ReactNode;
  rightComponent?: ReactNode;
  documentTitle: string;
  sheetUrl: string;
}

function Popup({
  show,
  onClose,
  title,
  sheet,
  rightComponent,
  documentTitle,
  sheetUrl,
}: IPopup) {
  const componentRef = useRef(null);

  if (!show || !sheet) {
    return null;
  }

  return (
    <SPopup>
      <STopBar>
        <SSheetName>{`${title}`}</SSheetName>
        <SGroup>
          <SButton
            onClick={() => {
              if (sheetUrl && window) {
                window.open(sheetUrl, '_blank')?.focus();
              }
            }}
          >
            open with google sheet
            <GoogleSheetLogo />
          </SButton>
          <SClose src={closeIcon} onClick={onClose} />
        </SGroup>
      </STopBar>
      <SDetail ref={componentRef}>{sheet}</SDetail>
      <SRightComponent>{rightComponent}</SRightComponent>
    </SPopup>
  );
}

export default Popup;
