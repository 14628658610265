import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Cubeloading } from '../../images/Cubeload.svg';
import InputForm from '../input/InputForm';
import useGetRemoteServiceWrapper from '../../custom-hooks/useGetRemoteServiceWrapper';
import { ISubmitModel } from '../../model/InputModel';
import ExportDataPopup from '../ExportDataPopup';
import DuplicateSheet from './actions/DuplicateSheet';

const SLoading = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 3;
`;

const SBody = styled.div`
  display: flex;
  width: 100%;
`;
function FormContainer({
  title,
  getDataFunction,
  getDocumentTitle,
  getSheet,
  getRightComponent,
  noInvoiceInput,
  withExportDate,
  withDocumentType,
  withConvertRate,
  withRemarks,
  checkBeforeExport,
  inputDateType,
  icon,
  exportData,
  generateFunction,
}: {
  title: string;
  getDataFunction: (params: ISubmitModel) => any;
  getSheet?: (data: any) => any;
  getDocumentTitle: (data: any) => string;
  getRightComponent?: (data: any) => any;
  withExportDate?: boolean;
  withDocumentType?: boolean;
  withConvertRate?: boolean;
  withRemarks?: boolean;
  checkBeforeExport?: boolean;
  inputDateType?: string;
  noInvoiceInput?: boolean;
  icon: any;
  exportData?: any;
  generateFunction?: (data: any) => any;
}) {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState<any>();
  const [popupDuplicate, setPopupDuplicate] = useState(false);
  const [pr, setPr] = useState<any>();
  useEffect(() => {
    if (!showPopup) {
      setData(null);
    }
  }, [showPopup]);

  const { loading, callRemoteServiceWrapper } =
    useGetRemoteServiceWrapper();

  const handleOnSubmit = async (
    params: ISubmitModel,
    fromDoubleCheck = false,
  ) => {
    setError('');
    callRemoteServiceWrapper(async () => {
      try {
        let data;
        if (fromDoubleCheck) {
          if (generateFunction) {
            data = await generateFunction({
              ...params,
              reason: reason,
            });
          }
        } else {
          setPr(params);
          data = await getDataFunction(params);
          if (data.webLink === '' && withRemarks) {
            if (generateFunction) {
              data = await generateFunction(params);
            }
          } else {
            setPopupDuplicate(true);
          }
        }
        setShowPopup(true);
        setData(data);
      } catch (e) {
        console.log(e);
        setError('Data not found.');
      }
    });
  };

  const docTitle = data?.name && getDocumentTitle(data);
  return (
    <>
      {popupDuplicate && data && generateFunction && (
        <DuplicateSheet
          webLink={data?.webLink}
          setPopupDuplicate={setPopupDuplicate}
          setData={setData}
          params={pr}
          reason={reason}
          setReason={setReason}
          generateFunction={() => {
            return handleOnSubmit(pr, true);
          }}
        />
      )}
      {!checkBeforeExport && (
        <ExportDataPopup
          show={showPopup}
          onClose={() => {
            setShowPopup(false);
          }}
          title={`${title} ${docTitle ? `- ${docTitle}` : ''}`}
          sheet={getSheet && getSheet(data)}
          rightComponent={
            getRightComponent && getRightComponent(data)
          }
          documentTitle={docTitle}
          sheetUrl={data?.sheetUrl}
        />
      )}
      {loading && (
        <SLoading>
          <Cubeloading />
        </SLoading>
      )}
      <SBody>
        <InputForm
          googleSheetLink={data || undefined}
          error={error}
          icon={icon}
          title={title}
          onSubmit={handleOnSubmit}
          withExportDate={withExportDate}
          withDocumentType={withDocumentType}
          checkBeforeExport={checkBeforeExport}
          inputDateType={inputDateType}
          noInvoiceInput={noInvoiceInput}
          withConvertRate={withConvertRate}
          withRemarks={withRemarks}
          exportData={exportData}
        />
      </SBody>
      {/* <ShowBilling></ShowBilling> */}
    </>
  );
}

export default FormContainer;
