import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import dropdownIcon from '../../images/dropdown-icon.svg';
import { dataApi } from '../../api/connectApi';
import { ISelectedBranch } from '../../model/InputModel';

const SBranchText = styled.div`
  margin-left: 10px;
`;

const SDropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SDropdown = styled.div`
  padding: 38px 0px 15px 0px;
  width: 95%;
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 18px #00000029;
  border-radius: 8px 8px 14px 14px;
  top: 100%;
  left: 20px;
  z-index: 1;
  overflow-y: auto;
`;

const SBranchChoice = styled.div`
  cursor: pointer;
  letter-spacing: 0px;
  color: #626262;
  font-size: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  &:hover {
    background: #a2316e 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
`;

const SBranchDropdown = styled.div`
  height: 48px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
  margin-top: 10px;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
  &.placeholder {
    color: #cccccc;
  }
`;

const SDropdownIcon = styled.img`
  cursor: pointer;
  &.open {
    transform: rotateX(180deg);
  }
  margin-left: auto;
`;

interface IBranchDropdown {
  selectedBranch: ISelectedBranch | null | undefined;
  onBranchChange: (branch: ISelectedBranch) => void;
  withDocumentType?: boolean;
}

function BranchDropdown({
  selectedBranch,
  onBranchChange,
  withDocumentType,
}: IBranchDropdown) {
  const [dropdown, setDropdown] = useState(false);
  const [branchChoice, setBranchChoice] = useState([]);

  useEffect(() => {
    const handleBranchChoice = async () => {
      const branch = await dataApi.getConnections();
      if (withDocumentType) {
        const result = branch.filter((e: any) => {
          return (
            e.tenantId === '6aa24259-f933-471c-bd6a-ebefedcfc9c4' ||
            e.tenantId === '6eb94e36-03b8-481c-830f-98bf7ec260d4'
          );
        });
        setBranchChoice(result);
      } else {
        setBranchChoice(branch);
      }
    };

    handleBranchChoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleBranch = (branchName: any, branchId: any) => {
    setDropdown(false);
    onBranchChange({ branchId, branchName });
  };
  return (
    <>
      <SBranchText>Branch</SBranchText>
      <SDropdownContainer>
        {dropdown && (
          <SDropdown>
            {branchChoice.map((branch: any) => (
              <SBranchChoice
                key={branch.tenantId}
                onClick={() =>
                  handleBranch(branch.tenantName, branch.tenantId)
                }
              >
                {branch.tenantName}
              </SBranchChoice>
            ))}
          </SDropdown>
        )}
        <SBranchDropdown
          className={!selectedBranch ? 'placeholder' : ''}
          onClick={() => handleDropdown()}
        >
          {selectedBranch
            ? selectedBranch.branchName
            : 'Select Branch'}
          <SDropdownIcon
            src={dropdownIcon}
            className={dropdown ? 'open' : ''}
          />
        </SBranchDropdown>
      </SDropdownContainer>
    </>
  );
}

export default BranchDropdown;
