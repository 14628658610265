import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../images/logo.svg';
import { UserContext } from '../contexts/UserContext';
import { ROUTE_MAIN_URL } from '../routes/RouteConstants';

const SImgProfile = styled.img`
  height: 40px;
  border-radius: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const SSelectedLine = styled.div`
  background: #a83372 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  height: 4px;
  margin-top: 7px;
`;
const SLine = styled.div`
  border: 1px solid ${(props) => props.theme.commonTheme.colors.main};
  height: 32px;
`;
const SIcon = styled.div`
  color: ${(props) => props.theme.commonTheme.colors.main};
  display: flex;
`;
const SAppName = styled.div`
  font-weight: bold;
  font-size: 28px;
  letter-spacing: 0.39px;
  margin-left: 20px;
`;
const SOrgName = styled.div`
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.29px;
`;
const STopNav = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 12px #00000029;
  opacity: 1;
  height: 102px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 60px 0px 60px;
`;
const SMenu = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &.selected {
    color: ${(props) => props.theme.commonTheme.colors.main};
  }
  &.profile {
    display: flex;
    align-items: center;
  }
`;

const menu = [
  {
    label: 'Billing',
    url: ROUTE_MAIN_URL.billing,
  },
  {
    label: 'Upload Receipt/Tax Invoice',
    url: ROUTE_MAIN_URL.uploadReceiptTaxInvoice,
  },
  // {
  //   label: 'Receipt/Tax Invoice',
  //   url: ROUTE_MAIN_URL.receiptTaxInvoice,
  // },
  // {
  //   label: 'Receipt/Tax Invoice Monthly Report',
  //   url: ROUTE_MAIN_URL.receiptTaxInvoiceMonthlyReport,
  // },
  {
    label: 'Receive Voucher',
    url: ROUTE_MAIN_URL.receiveVoucher,
  },
  {
    label: 'Payment Voucher',
    url: ROUTE_MAIN_URL.paymentVoucher,
  },
];

function TopNav() {
  const history = useHistory();
  const { user }: any = useContext(UserContext);
  const [selectedUrl, setSelectedUrl] = useState(
    window.location.pathname,
  );

  const handleOnClick = (menu: { label: string; url: string }) => {
    history.push(menu.url);
  };

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setSelectedUrl(pathname);
  }, [pathname]);

  return (
    <STopNav>
      <SIcon>
        <Logo />
        <SAppName>
          Accounting Portal
          <SOrgName>gopomelo</SOrgName>
        </SAppName>
      </SIcon>
      {menu.map((menu, index) => (
        <SMenu
          onClick={() => handleOnClick(menu)}
          className={selectedUrl === menu.url ? 'selected' : ''}
          key={`top-nav-menu-${index}`}
        >
          {menu.label}
          {selectedUrl === menu.url && <SSelectedLine />}
        </SMenu>
      ))}
      <SLine />
      <SMenu className="profile">
        {user && (
          <>
            <SImgProfile src={user.profile} />
            {user.name}
          </>
        )}
      </SMenu>
    </STopNav>
  );
}

export default TopNav;
