import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import BillingForm from '../components/forms/BillingForm';
import PaymentVoucherForm from '../components/forms/PaymentVoucherForm';
// import ReceiptTaxInvoiceForm from '../components/forms/ReceiptTaxInvoiceForm';
// import ReceiptTaxInvoiceMonthlyReportForm from '../components/forms/ReceiptTaxInvoiceMonthlyReportForm';
import ReceiveVoucherForm from '../components/forms/ReceiveVoucherForm';
import UploadReceiptTaxInvoiceForm from '../components/forms/UploadReceiptTaxInvoiceForm';
import TopNav from '../components/TopNav';
import bg from '../images/bg.svg';
import { ROUTE_MAIN_URL } from '../routes/RouteConstants';
import ReceiptTaxInvoiceForm from '../components/forms/ReceiptTaxInvoiceForm';

const SMainPage = styled.div`
  font-family: ${(props) => props.theme.commonTheme.fontFamily.body};
  height: 100%;
`;

const SBody = styled.div`
  height: 100%;
`;

const SBg = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 0;
`;

const SWhiteBg = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: white;
  opacity: 0.6;
  z-index: 1;
`;

function MainPage() {
  return (
    <SMainPage>
      <TopNav />
      <SBody>
        <SBg />
        <SWhiteBg />
        <Switch>
          <Route path={ROUTE_MAIN_URL.billing}>
            <BillingForm />
          </Route>
          <Route exact path={ROUTE_MAIN_URL.receiptTaxInvoice}>
            <ReceiptTaxInvoiceForm />
          </Route>
          {/* <Route
            exact
            path={ROUTE_MAIN_URL.receiptTaxInvoiceMonthlyReport}
          >
            <ReceiptTaxInvoiceMonthlyReportForm />
          </Route> */}
          <Route path={ROUTE_MAIN_URL.receiveVoucher}>
            <ReceiveVoucherForm />
          </Route>
          <Route path={ROUTE_MAIN_URL.paymentVoucher}>
            <PaymentVoucherForm />
          </Route>
          <Route exact path={ROUTE_MAIN_URL.uploadReceiptTaxInvoice}>
            <UploadReceiptTaxInvoiceForm />
          </Route>
          <Route
            path={`*`}
            render={() => (
              <Redirect to={ROUTE_MAIN_URL.uploadReceiptTaxInvoice} />
            )}
          />
        </Switch>
      </SBody>
    </SMainPage>
  );
}

export default MainPage;
