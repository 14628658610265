import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import Login from "../pages/Login";
import MainPage from "../pages/MainPage";
import { BrowserRouter as Router } from 'react-router-dom';

function ProjectedContainer() {
  const { user }: any = useContext(UserContext);
  return (
    <Router>
      {user ? <MainPage /> : <Login />}
    </Router>
  )
}
export default ProjectedContainer;
