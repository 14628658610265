const common = {
  fontFamily: {
    body: `"Product Sans", sans-serif`,
    title: `Product Sans`,
  },
  fontSizes: {
    extraSmall: '12px',
    small: '14px',
    medium: '20px',
    big: '30px',
  },
  lineHeights: {
    classic: 1.58,
  },
  colors:{
    main:'#A83372'
  }
};

export default common;
