import React from 'react';
import './App.css';
import ThemeContextProvider from './contexts/theme/Theme';
import UserContextProvider from './contexts/UserContext';
import ProjectedContainer from './components/ProjectedContainer'

const App = () => {
  return (
    <ThemeContextProvider>
      <UserContextProvider>
        <ProjectedContainer/>
      </UserContextProvider>
    </ThemeContextProvider>
  );
};

export default App;
