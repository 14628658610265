import React from 'react';
import moment from 'moment';
import { dataApi } from '../../api/connectApi';
import FormContainer from './FormContainer';
import PaymentVoucherSheet from '../sheets/PaymentVoucherSheet';
import paymentIcon from '../../images/payment-voucher-icon.svg';

function PaymentVoucherForm() {
  return (
    <FormContainer
      icon={paymentIcon}
      title="Payment Voucher"
      getDataFunction={(params) => {
        return dataApi.getPv(
          params.invoices,
          params.selectedBranch?.branchId,
          params.selectedBranch?.branchName,
        );
      }}
      getSheet={(data) => {
        if (data) {
          return <PaymentVoucherSheet data={data} />;
        }
      }}
      getDocumentTitle={(data) => {
        return `Payment Voucher ${data.documentNo} ${moment(
          data?.date,
        ).format('DD-MMM-YYYY')}`;
      }}
    />
  );
}

export default PaymentVoucherForm;
