import axios from 'axios';
import { getUserIdToken, saveUserIdToken } from '../utils/SessionStorageUtil';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const accountingPortalAxios = axios.create({
  baseURL: BASE_URL,
});

const reLogin = () => {
  return new Promise((resolve)=> {
    window.google.accounts.id.initialize({
      client_id : process.env.REACT_APP_CLIENT_ID,
      callback : (response:any) => {
        saveUserIdToken(response.credential);
        resolve(response.credential);
      }, 
      auto_select : true,
    });
    window.google.accounts.id.prompt();
  });
}
accountingPortalAxios.interceptors.request.use ( async function (config) {
  let token = getUserIdToken();
  if(token != null) {
    const userToken = JSON.parse(window.atob(token.split('.')[1]));
    if(Date.now() >= userToken.exp * 1000) {
      await reLogin();
    }
  }
  
  config.headers.Authorization = 'Bearer ' + getUserIdToken();
  return config;
});

export default accountingPortalAxios;
