const SESSION_NAME_USER = 'SESSION_NAME_USER';

/**
 * Save the user login data to the session
 */
export const saveUserLoginData = (user: any) => {
  sessionStorage.setItem(SESSION_NAME_USER, JSON.stringify(user));
};

/**
 * Save the user login data to the session
 */
export const getUserLoginData = () =>
  sessionStorage.getItem(SESSION_NAME_USER)
    ? JSON.parse(sessionStorage.getItem(SESSION_NAME_USER) || '')
    : null;

const SESSION_ID_TOKEN = 'SESSION_ID_TOKEN';

/**
 * Save the user login data to the session
 */
export const saveUserIdToken = (token: string) => {
  sessionStorage.setItem(SESSION_ID_TOKEN, token);
};

/**
 * Get the user login data from the session
 */
export const getUserIdToken = () =>
  sessionStorage.getItem(SESSION_ID_TOKEN);
