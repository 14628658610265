import accountingPortalAxios from './service';

let branchListCache: any = null;

const getConnections = async () => {
  if (branchListCache === null) {
    const res = await accountingPortalAxios.get(`/connections`);
    branchListCache = res.data;
  }
  return branchListCache;
};

const getRv = async (invoice: any, selectBranchId: any) => {
  const result = await accountingPortalAxios.post('rv', {
    tenantId: selectBranchId,
    invoiceNumbers: invoice,
  });
  return result.data;
};

const getPv = async (
  invoice: any,
  selectBranchId: any,
  selectBranchName: any,
) => {
  const result = await accountingPortalAxios.post('pv', {
    tenantId: selectBranchId,
    branchName: selectBranchName,
    invoiceNumbers: invoice,
  });
  return result.data;
};

getConnections();

export const dataApi = {
  getConnections,
  getRv,
  getPv,
};
