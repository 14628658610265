import React from 'react';
import moment from 'moment';
import { dataApi } from '../../api/connectApi';
import ReceiveVoucherSheet from '../sheets/ReceiveVoucherSheet';
import FormContainer from './FormContainer';
import receiveVoucherIcon from '../../images/receive-voucher-icon.svg';
function ReceiveVoucherForm() {
  return (
    <FormContainer
      icon={receiveVoucherIcon}
      title="Receive Voucher"
      getDataFunction={(params) => {
        return dataApi.getRv(
          params.invoices,
          params.selectedBranch?.branchId,
        );
      }}
      getSheet={(data) => {
        if (data) {
          return <ReceiveVoucherSheet data={data} />;
        }
      }}
      getDocumentTitle={(data) => {
        return `Receive Voucher ${data.documentNo} ${moment(
          data?.date,
        ).format('DD-MMM-YYYY')}`;
      }}
    />
  );
}

export default ReceiveVoucherForm;
