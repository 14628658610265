
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '../contexts/UserContext';

const SBox = styled.div`
  font-weight: bold;
  width: 400px;
  height: 250px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 12px #00000029;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  text-align: center;
  color: #a83372;
`;

const SLogin = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

/**
 * it doesn't matter if you are already logged in or not, if you comes to /login you will be relogin
 * https://developers.google.com/identity/sign-in/web/sign-in
 * in localstorage
 * {
 *  user: {
 *    token: // token from google
 *  }
 * }
 */

declare global {
  interface Window {
    google: any;
  }
}
declare const google: any;

function Login() {
  const { setUser }: any = useContext(UserContext);

  function credentialHandler(response: any) {
    const userData = JSON.parse(window.atob(response.credential.split('.')[1]));
    setUser({
      name: userData.name,
      profile: userData.picture
    }, response.credential); 
  }

  useEffect(() => {
    google.accounts.id.initialize({
      client_id : process.env.REACT_APP_CLIENT_ID,
      callback : credentialHandler,
      auto_select : true, 
      
    }); 
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large" }
    );
    google.accounts.id.prompt((notification: any) => {
      console.log('on prompt notification', notification)
    });
    // eslint-disable-next-line
  }, [google]);

  return (
    <SLogin>
      <SBox>
        <p>Please sign in</p>
        <div id="buttonDiv"
          data-client_id={process.env.REACT_APP_CLIENT_ID}>
        </div>
      </SBox>
    </SLogin>
  );
}

export default Login;



