const theme = {
  colors: {
    text: {
      primary: '#fff',
    },
    palette: {
      primary: '#3C4043',
      secondary: '#00A94F',
    },
  },
};

export default theme;
