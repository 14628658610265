import React from 'react';
import FormContainer from './FormContainer';
import ReceiptTaxInvoiceService from '../../api/uploadReceiptTaxInvoiceService';
import ReceiptTaxInvoiceSheet from '../sheets/ReceiptTaxInvoiceSheet';
import RemoveReceiptTaxInvoice from './actions/RemoveReceiptTaxInvoice';
import receiptIcon from '../../images/receipt-icon.svg';

function UploadReceiptTaxInvoiceForm() {
  return (
    <React.Fragment>
      <FormContainer
        icon={receiptIcon}
        title="Upload Receipt Tax/Invoice"
        withExportDate
        withDocumentType
        checkBeforeExport
        withConvertRate
        withRemarks
        getDataFunction={async (params: any) => {
          return ReceiptTaxInvoiceService.check(
            params.selectedBranch.branchName,
            params.selectedDocumentType.documentTypeId,
            params.invoices,
            params.exportDate,
          );
        }}
        generateFunction={async (params: any) => {
          return ReceiptTaxInvoiceService.postReceiptTaxInvoice(
            params.selectedBranch?.branchId,
            params.selectedDocumentType.documentTypeId,
            params.invoices,
            params.exportDate,
            params.convertRate,
            params.remarks,
            params.reason,
          );
        }}
        exportData={async (params: any) => {
          return ReceiptTaxInvoiceService.exportReceiptTaxInvoice(
            params.sheetId,
            params.branchCompanyName,
          );
        }}
        getSheet={(data) => {
          if (data) {
            return <ReceiptTaxInvoiceSheet data={data} />;
          }
        }}
        getRightComponent={(data) => {
          return <RemoveReceiptTaxInvoice data={data} />;
        }}
        getDocumentTitle={(data) => {
          return `${data.docNo},${data.refInvoiceNo} ${data.customer.name}`;
        }}
      />
      <div></div>
    </React.Fragment>
  );
}

export default UploadReceiptTaxInvoiceForm;
