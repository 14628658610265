const theme = {
  colors: {
    text: {
      primary: '#3C4043',
    },
    palette: {
      primary: '#fff',
      secondary: '#c8fff773',
    },
  },
};

export default theme;
