import React, { useState } from 'react';
import {
  STableContainer,
  STableTitle,
  STable,
  STHead,
  STr,
  STh,
  STd,
  STBody,
  STFoot,
  SFooterDivContainer,
  SFooterTitle,
  SFooterValue,
} from './TableUi';
import EditableCell from './EditableCell';
import { AlignType, DataType, renderValueByType } from './TableUtils';
export interface IDataTable {
  title?: string;
  // eslint-disable-next-line
  data: (any)[];
  columnHeaders: {
    title: string;
    columnValue: string;
    width: string;
    align?: AlignType;
    type?: DataType;
    editable?: boolean;
  }[];
  tableFooter?: {
    title: string;
    value: string;
    type: DataType;
  }[];
  displayTotal?: boolean;
}

function Table({
  data,
  columnHeaders,
  title,
  tableFooter,
  displayTotal,
}: IDataTable) {
  const firstNumberColumn = columnHeaders.findIndex(
    (c) => c.type === 'number',
  );

  const numberColumns = columnHeaders.filter(
    (c) => c.type === 'number',
  );

  const [internalData, setInternalData] = useState([...data]);

  return (
    <STableContainer>
      <STableTitle>{title}</STableTitle>
      <STable>
        <STHead>
          <STr>
            {columnHeaders.map((column, index) => (
              <STh key={`table-header-column-${index}`}>
                {column.title}
              </STh>
            ))}
          </STr>
        </STHead>
        <STBody>
          {internalData.map((d, index) => (
            <STr key={`table-body-row-${index}`}>
              {columnHeaders.map((column, indexc) => (
                <STd
                  key={`table-body-row-${index}-column-${indexc}`}
                  style={{
                    textAlign: column.align || 'left',
                    width: column.width,
                  }}
                  className={column.editable ? 'editable' : ''}
                >
                  {column.editable ? (
                    <EditableCell
                      value={d[column.columnValue]}
                      type={column.type}
                      align={column.align}
                      onValueSubmitted={(value) => {
                        setInternalData((previousState) => {
                          const newState = [...previousState];
                          newState[index][column.columnValue] = value;
                          return newState;
                        });
                      }}
                    />
                  ) : (
                    renderValueByType(
                      column.type,
                      d[column.columnValue],
                    )
                  )}
                </STd>
              ))}
            </STr>
          ))}
          {displayTotal && firstNumberColumn >= 0 && (
            <STr>
              <STd colSpan={firstNumberColumn}>Total</STd>
              {numberColumns.map((nc) => {
                const numValues = internalData.map(
                  (d) => d[nc.columnValue],
                );
                const total = numValues.reduce(
                  (accumulator, currentValue) =>
                    Number(accumulator) + Number(currentValue),
                  0,
                );
                return (
                  <STd
                    key={`total-${nc.columnValue}`}
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    {total > 0
                      ? renderValueByType('number', total)
                      : ''}
                  </STd>
                );
              })}
            </STr>
          )}
        </STBody>
      </STable>
      {tableFooter && (
        <STFoot>
          {tableFooter.map((tf, index) => (
            <SFooterDivContainer key={`footer-row-${index}`}>
              <SFooterTitle>{tf.title}</SFooterTitle>
              <SFooterValue>
                {renderValueByType(tf.type, tf.value)}
              </SFooterValue>
            </SFooterDivContainer>
          ))}
        </STFoot>
      )}
    </STableContainer>
  );
}

export default Table;
