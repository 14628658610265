import React from 'react';
import styled from 'styled-components/macro';

const SInput = styled.input`
  border: none;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 24px;
  opacity: 1;
  margin-top: 10px;
  padding: 0px 20px;
  font-size: 16px;
  &::placeholder {
    color: #cccccc;
  }
  &:focus {
    outline: none;
  }
`;

const SRemarksText = styled.div`
  margin-top: 30px;
  margin-left: 10px;
`;

function RemarksInput({
  onRemarksChange,
}: {
  onRemarksChange: (remarks: string) => void;
}) {
  return (
    <>
      <SRemarksText>Remarks</SRemarksText>
      <SInput
        placeholder="Enter Remark"
        onChange={(event) => onRemarksChange(event.target.value)}
      />
    </>
  );
}

export default RemarksInput;
