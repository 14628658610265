import React from 'react';
import Table from '../tables/Table';
import moment from 'moment';
import {
  SSheet,
  SSheetHeader,
  SSheetTitle,
  SSheetHeaderRowTitle,
} from './SheetUI';
import { AlignType, DataType } from '../tables/TableUtils';

import styled from 'styled-components/macro';

const SSheetHeaderContent = styled.div`
  font-size: 0.8rem;
`;

const SContact = styled.div`
  float: left;
`;
const SDateAndRefNo = styled.div`
  float: right;
`;

const SRowValue = styled.div``;

const Sfooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3%;
`;

const SSignatureRow = styled.div`
  display: grid;
  grid-template-columns: 30% 30%;
  grid-column-gap: 40%;
`;

const SLine = styled.div`
  width: 220px;
  border-top: 1px solid black;
`;

const SAddressContainer = styled.div`
  text-align: center;
`;

const SAddressLine = styled.div`
  text-align: center;
`;

export default function BillingSheet({ data }: { data: any }) {
  const { supplierName, date, documentNo } = data.header;
  const { organizationName, address, phone } = data.footer;

  const sheetHeader = {
    supplierName,
    date,
    documentNo,
  };
  const tableConfig = {
    columnHeaders: [
      {
        title: 'No',
        columnValue: `no`,
        width: '15%',
        type: 'string' as DataType,
      },
      {
        title: 'Invoice No.',
        columnValue: `invoiceNo`,
        width: '15%',
        align: 'right' as AlignType,
        type: 'string' as DataType,
      },
      {
        title: 'Reference PO',
        columnValue: 'ReferencePO',
        width: '15%',
        align: 'right' as AlignType,
        type: 'string' as DataType,
      },
      {
        title: 'Amount',
        columnValue: `subtotal`,
        width: '15%',
        align: 'right' as AlignType,
        type: 'number' as DataType,
      },
      {
        title: 'Vat 7%',
        columnValue: 'vat',
        width: '15%',
        align: 'right' as AlignType,
        type: 'number' as DataType,
      },
      {
        title: `Total Amount`,
        columnValue: 'totalAmount',
        width: '15%',
        align: 'right' as AlignType,
        type: 'number' as DataType,
      },
    ],
    data: data?.billingDetail || [],
  };
  return (
    <SSheet>
      <SSheetHeader>
        <SSheetTitle>Billing </SSheetTitle>
        <SSheetHeaderContent>
          <SContact>
            <SSheetHeaderRowTitle>Customer</SSheetHeaderRowTitle>
            <SRowValue>{sheetHeader?.supplierName}</SRowValue>
          </SContact>
          <SDateAndRefNo>
            <SSheetHeaderRowTitle>Date</SSheetHeaderRowTitle>
            <SRowValue>
              {`${moment(sheetHeader?.date).format('DD-MMM-YYYY')}`}
            </SRowValue>
            <br />
            <SSheetHeaderRowTitle>No.</SSheetHeaderRowTitle>
            <SRowValue>{sheetHeader?.documentNo}</SRowValue>
            <br />
          </SDateAndRefNo>
        </SSheetHeaderContent>
      </SSheetHeader>
      <Table {...tableConfig} displayTotal />

      <br />

      <Sfooter>
        <SSignatureRow>
          <div>Prepared by : </div>
        </SSignatureRow>
        <SSignatureRow>
          <SLine>
            Date :
          </SLine>
        </SSignatureRow>
      </Sfooter>

      <Sfooter>
        <SSignatureRow>
          <div>Receiver</div>
        </SSignatureRow>
        <SSignatureRow>
          <SLine>
            Date :
          </SLine>
          Due Date :{' '}
        </SSignatureRow>
      </Sfooter>

      <br />
      <Sfooter>
        <SContact>
          Note
        </SContact>

        <br /><br />
        <SAddressContainer>
          <SAddressLine>{organizationName}</SAddressLine>
          <SAddressLine>{address.AddressLine1} {address.AddressLine2}</SAddressLine>
          <SAddressLine>{address.AddressLine3} {address.AddressLine4}</SAddressLine>
          <SAddressLine>Tel: {phone}</SAddressLine>
        </SAddressContainer>
      </Sfooter>

      <br /><br /><br />
    </SSheet>
  );
}
