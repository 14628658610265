import styled from 'styled-components/macro';

export const STableContainer = styled.div`
  font-size: 0.8rem;
  margin-bottom: 20px;
  width: 100%;
`;

export const STableTitle = styled.h3``;

export const STable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const STHead = styled.thead``;
export const STBody = styled.tbody``;
export const STFoot = styled.div``;

export const STr = styled.tr``;
export const STh = styled.td`
  border: 1px solid #ddd;
  background-color: #f2f2f2;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
`;
export const STd = styled.td`
  border: 1px solid #ddd;
  padding: 5px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  &.editable {
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

export const SFooterDivContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SFooterTitle = styled.div`
  padding: 5px;
  font-size: 0.95rem;
`;
export const SFooterValue = styled.div`
  padding: 5px;
  font-size: 0.95rem;
`;
