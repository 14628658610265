import React, { useState, createContext } from 'react';
import {
  getUserLoginData,
  saveUserLoginData,
  saveUserIdToken,
} from '../utils/SessionStorageUtil';

export const UserContext = createContext({
  user: getUserLoginData(),
  setUser: {},
});

const UserContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState(getUserLoginData());

  const handleSetUser = (user: any, token: string) => {
    setUser(user);
    saveUserLoginData(user);
    saveUserIdToken(token);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser: handleSetUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
