import styled from 'styled-components/macro';

export const SSheet = styled.div`
  z-index: 99999;
  width: 21cm;
  /* min-height: 19.7cm; */
  background-color: white;
  padding: 10px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  font-size: 0.95rem;
`;

export const SSheetHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SSheetTitle = styled.h2`
  align-self: center;
 
`;
export const SSheetTopic = styled.h2`
  align-self: center;
  margin: 5px;
  padding: 0px;
`;
export const SSheetHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SSheetHeaderRowEntry = styled.div`
  display: flex;
  max-width: 70%;
`;

export const SSheetHeaderRowTitle = styled.div`
  font-weight: 600;
  &:after {
    content: ':';
  }
`;

export const SSheetHeaderRowValue = styled.div`
  max-width: 70%;
  margin-left: 5px;
`;

export const STableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
