import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { AlignType, DataType, renderValueByType } from './TableUtils';

interface IEditableCell {
  value: string;
  type: DataType;
  align?: AlignType;
  onValueSubmitted: (value: any | undefined) => void;
}

export const SEditableCellContainer = styled.div`
  cursor: pointer;
  width: 100%;
  input {
    width: 90%;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

function EditableCell({
  value,
  type,
  align,
  onValueSubmitted,
}: IEditableCell) {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnValueSubmitted = () => {
    setIsEditing(false);
    const value =
      type === 'number'
        ? Number(inputRef?.current?.value)
        : inputRef?.current?.value;
    onValueSubmitted(value);
  };

  const inputProps =
    type === 'number'
      ? {
          type: 'number',
          step: 'any',
        }
      : {
          type: 'text',
        };

  return (
    <SEditableCellContainer
      style={{ textAlign: align }}
      onClick={() => {
        setIsEditing(true);
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          inputRef?.current?.focus();
        });
      }}
    >
      {isEditing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleOnValueSubmitted();
          }}
        >
          <input
            {...inputProps}
            ref={inputRef}
            defaultValue={value}
            style={{ textAlign: align }}
            onBlur={() => {
              handleOnValueSubmitted();
            }}
          />
        </form>
      ) : (
        renderValueByType(type, value) || <span>&nbsp;</span>
      )}
    </SEditableCellContainer>
  );
}

export default EditableCell;
