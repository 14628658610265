import accountingPortalAxios from './service';

const api = {
  postReceiptTaxInvoice: async (
    tenantId: string,
    documentType: string,
    invoiceNumber: string,
    exportDate: string,
    convertRate: number,
    remarks: string,
    reason: string,
  ) => {
    const result = await accountingPortalAxios.post(
      '/receipt-tax-invoice/upload',
      {
        tenantId,
        documentType,
        invoiceNumber,
        exportDate,
        convertRate,
        remarks,
        reason,
      },
    );
    return result.data;
  },
  check: async (
    branchName: string,
    documentType: string,
    invoiceNumber: string,
    exportDate: string,
  ) => {
    const result = await accountingPortalAxios.post(
      '/receipt-tax-invoice/check',
      {
        branchName: branchName,
        documentType: documentType,
        invoiceNumber: invoiceNumber,
        exportDate: exportDate,
      },
    );
    return result.data;
  },
  exportReceiptTaxInvoice: async (
    sheetId: string,
    branchCompanyName: string,
  ) => {
    const result = await accountingPortalAxios.post(
      '/receipt-tax-invoice/send',
      {
        sheetId,
        branchCompanyName,
      },
    );
    return result.data;
  },
};

export default api;
